import React, { useState } from 'react'
import * as Yup from 'yup'
import { withFirebase } from '../firebase'
import styled from 'styled-components'
import { Link } from 'gatsby'
import TextField from '@material-ui/core/TextField'
import { Formik } from 'formik'
import { Flex, Box } from 'reflexbox'
import {
  Button,
  FormBox,
  FormFields,
  FormField,
  FormError,
  FormCopy,
  Errors,
} from '../page/components'

const userSchema = Yup.object().shape({
  email: Yup.string()
    .email('Please enter a valid email address')
    .required('New email address is required'),
})

const INITIAL_STATE = {
  submitting: false,
  submitted: false,
  submitErrors: [],
}

const ChangeForm = ({ firebase, authUser }) => {
  if (firebase && firebase.auth && firebase.auth.currentUser) {
    const [status, setStatus] = useState(INITIAL_STATE)

    const user = firebase.auth.currentUser

    const onSubmit = values => {
      if (status.submitting) return null

      setStatus(prevState => ({
        ...prevState,
        submitting: true,
        submitted: false,
        submitErrors: [],
      }))

      const authUpdateFunc = firebase.functions.httpsCallable(
        'authEmailUpdateRequest'
      )
      const funcData = {
        studentId: authUser.uid,
        currEmail: user.email,
        newEmail: values.email,
      }

      authUpdateFunc(funcData)
        .then(res => {
          console.log('✔ Verification sent', res)
          setStatus(prevState => ({
            ...prevState,
            submitting: false,
            submitted: true,
            submitErrors: [],
          }))
        })
        .catch(error => {
          const { message } = error

          setStatus(prevState => ({
            ...prevState,
            submitting: false,
            submitted: true,
            submitErrors: [`We had a problem submitting! ${message}`],
          }))
        })
    }

    let errorContent
    if (status.submitErrors.length > 0) {
      errorContent = (
        <Flex flexWrap={['wrap']} width={`100%`} mb={[0]}>
          <Box width={[1, 1]}>
            <Errors>
              {status.submitErrors.map((error, i) => {
                return <p key={`error-${i}`}>{error}</p>
              })}
            </Errors>
          </Box>
        </Flex>
      )
    }

    if (status.submitted && status.submitErrors.length === 0) {
      return (
        <FormBox processing={status.submitting}>
          <FormFields>
            <FormField width={`100%`}>
              <FormCopy>
                <p>
                  Great! We've sent a verification to the new email address.
                  Please follow the link to confirm your new email.
                </p>
                <br />
                <br />
                <Link to="/account">Back</Link>
              </FormCopy>
            </FormField>
          </FormFields>
        </FormBox>
      )
    } else {
      const { submitting } = status
      const buttonText = submitting ? `Submitting...` : `Submit`

      return (
        <Formik
          initialValues={{
            email: '',
          }}
          validationSchema={userSchema}
          onSubmit={values => {
            onSubmit(values)
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <FormBox processing={status.submitting}>
              <form onSubmit={handleSubmit}>
                <FormFields>
                  <FormField width={`100%`}>
                    <FormCopy>
                      Enter your new email below and a verification will be
                      sent.
                    </FormCopy>
                  </FormField>

                  <FormField width={`100%`}>
                    <StyledTextField
                      label="Current"
                      name="email-old"
                      value={user.email}
                      margin="normal"
                      disabled
                    />
                  </FormField>

                  <FormField width={`100%`}>
                    <StyledTextField
                      label="New Email"
                      name="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      margin="normal"
                    />
                    {errors.email && touched.email ? (
                      <FormError>{errors.email}</FormError>
                    ) : null}
                  </FormField>
                  {errorContent}
                  <FormField marginRight={`50%`}>
                    <Button type="submit">{buttonText}</Button>
                  </FormField>
                  <FormField marginRight={`50%`}>
                    <FormCopy>
                      <p>
                        <Link to="/account">Back</Link>
                      </p>
                    </FormCopy>
                  </FormField>
                </FormFields>
              </form>
            </FormBox>
          )}
        </Formik>
      )
    }
  } else {
    return null
  }
}

export default withFirebase(ChangeForm)

const StyledTextField = styled(TextField)`
  ${props => props.theme.materialTextField()}
`
